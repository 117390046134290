/*======================================
  General Style Start
======================================*/
.container {
  padding-left: 120px;
  padding-right: 120px;
}

.btn {
  min-width: 95px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
  color: #FF6500;
  border-radius: 0 0 20px 0;
  max-width: fit-content;
  border: 2px solid #FF6500;
}

.btn:hover,
.btn:focus {
  color: #381602;
  border-color: #381602;
}

.btn.btn-solid,
.btn.btn-solid:hover,
.btn.btn-solid:focus {
  color: #fff;
  background-color: #FF6500;
}

.description {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 24px;
}

.button {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0em;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.08em;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
}

.heading-bg {
  padding: 8px;
  background-color: #FF6500;
}

.heading-bg .heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.09em;
  text-align: center;
  color: #000;
  margin-bottom: 0;
  text-transform: uppercase;
}

/*======================================
  General Style End
======================================*/
/*!============================================================================*/
/*======================================
  Navbar Style Start
======================================*/
.navbar {
  position: absolute;
  top: 35px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;
  padding: 0;
}

.navbar .container {
  position: relative;
}

.navbar .navbar-brand {
  padding: 0;
}

.navbar .navbar-nav {
  width: 100%;
  column-gap: 80px;
  justify-content: center;
}

.navbar .nav-item-btn {
  border-radius: 0px 0px 20px 0px;
}

.navbar .navbar-nav .nav-item .nav-link {
  font-weight: 300;
  --bs-nav-link-color: #fff;
  --bs-nav-link-hover-color: #fff;
}

/*======================================
  Navbar Style End
======================================*/
/*!============================================================================*/
/*======================================
  Homepage Style Start
======================================*/

.homepage-hero {
  overflow: hidden;
  background-color: #000000;
  background: linear-gradient(180deg, #000000 15%, #381602 98.5%);
  padding-top: 125px;
}

.hero-sub-heading {
  color: #fff;
}

.hero-heading {
  font-size: 64px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.22em;
  text-align: center;
  color: #FF6500;
  margin-bottom: 40px;
}

.hero-img {
  display: block;
  max-width: 600px;
  max-height: 100%;
  margin-left: auto;
  position: relative;
  margin-right: auto;
  /* animation: glow 5s infinite; */
}

.hero-img .img-fluid {
  width: 100%;
}

@keyframes glow {
  0% {
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.15));
  }

  50% {
    filter: drop-shadow(0px 0px 30px rgba(255, 255, 255, 0.75));
  }

  100% {
    filter: drop-shadow(0px 0px 0px rgba(255, 255, 255, 0.15));
  }
}

.hero-nav {
  background-color: #FF6500;
  border-radius: 0px 0px 20px 20px;
}

.hero-nav .navbar-nav {
  flex-direction: row;
  justify-content: center;
}

.hero-nav .navbar-nav .nav-item {
  width: 100%;
  max-width: 225px;
  text-align: center;
}

.hero-nav .navbar-nav .nav-item .nav-link {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.01em;
  color: #000000;
  text-transform: uppercase;
}

.mint {
  padding: 40px 0 80px;
}

.mint-heading {
  gap: 24px;
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  flex-direction: column;
  justify-content: center;
}

.mint-heading .dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 1px solid #fff;
}

.mint-main {
  padding: 40px 20px 0;
  max-width: 940px;
  margin-left: auto;
  margin-right: auto;
  background-size: 417px 470px;
  background-repeat: no-repeat;
  background-position: left -15%;
  background-image: url('./images/minting.png');
}

.mint-boerder {
  padding-bottom: 30px;
  border: 1px solid #fff;
  border-radius: 20px 20px 0 0;
}

.minting-description {
  padding: 40px 0;
}

.mint-box {
  width: 100%;
  display: flex;
}

.mint-box.gray {
  background-color: #333333;
  border-bottom-left-radius: 20px;
}

.mint-box.orange {
  background-color: #FF6500;
  border-bottom-right-radius: 20px;
}

.mint-box-items {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.mint-box-item {
  padding: 24px 0;
  text-align: center;
}

.mint-box-item .small-title {
  font-size: 24px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: -0.08em;
  text-align: center;
  margin-bottom: 8px;
}

.mint-box-item .description {
  text-align: center;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: center;
}

.mint-box.gray .mint-box-item .description,
.mint-box.gray .mint-box-item .small-title {
  color: #fff;
}

.mint-box.orange .mint-box-item .description,
.mint-box.orange .mint-box-item .small-title {
  color: #000;
}

.web-bottom {
  z-index: 1;
  position: relative;
}

.web-bottom::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-size: 100% 85%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-image: url('./images/sea.png');
}

.web-bottom::before {
  content: '';
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  height: 150vh;
  position: absolute;
  background: linear-gradient(180deg, #000000 15%, #381602 98.5%);
}

.boundaries {
  padding-bottom: 257px;
}

.boundaries .row {
  max-width: 900px;
  padding-top: 80px;
  margin-left: auto;
  margin-right: auto;
}

.boundaries .description {
  width: 210px;
  color: #000;
  padding: 16px;
  margin-bottom: 0;
  border-radius: 10px;
  background-color: #fff;
}

.features-list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  flex-direction: row;
}

.features-list-item {
  width: 363px;
  color: #fff;
  height: 125px;
  padding: 10px;
  border: 2px solid #fff;
  border-radius: 0px 20px 0px 0px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.03), rgba(255, 255, 255, 0.03));
  display: flex;
  align-items: flex-end;
}

.features-list-item:nth-child(2) {
  color: #FF6500;
  border-radius: 0;
  margin-left: auto;
  border-color: #FF6500;
}

.holding-chart {
  max-width: 900px;
  margin-left: auto;
  padding-top: 30px;
  position: relative;
  margin-right: auto;
  padding-bottom: 15px;
}

.holding-chart .holding-heading {
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.09em;
  text-align: center;
  position: absolute;
  right: 32px;
  bottom: 40px;
  margin-bottom: 0;
  text-transform: uppercase;
  color: #FF6500;
}

.community {
  padding-bottom: 33px;
}

.community .row {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.community-main {
  z-index: 1;
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 64px;
  padding: 0 25px 25px;
}

.community-main .community-dots {
  max-width: 40px;
  flex: 0 0 40px;
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.community-main .community-dot {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: #FF6500;
}

.community-main::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 249px;
  left: 0;
  bottom: 0;
  border-radius: 20px 20px 0px 0px;
  background: #00000066;
  z-index: -1;
}

.community-bottom {
  background-color: #FF6500;
  border-radius: 0 0 20px 20px;
  text-align: center;
}

.community-bottom .btn {
  border: 0;
  width: 100%;
  display: flex;
  max-width: 100%;
  border-radius: 0;
  border-radius: 0 0 20px 20px;
  background-color: transparent;
}

.footer {
  background-color: #000;
  border-radius: 20px 20px 0 0;
  padding: 40px 0 25px;
}

.copyright {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-bottom: 1em;
}

.footer-links {
  display: flex;
  row-gap: 40px;
  flex: 0 0 50%;
  max-width: 50%;
  flex-direction: column;
}

.footer-links .footer-link-item {
  text-align: right;
}

.footer-links .footer-link {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: 0em;
  text-align: right;
  color: #fff;
  text-transform: lowercase;
  text-decoration: none;
}

/*======================================
  Homepage Style End
======================================*/
/*!============================================================================*/
/*======================================
  Responsive Style Start
======================================*/

@media screen and (max-width: 991px) {
  .container {
    padding-left: 60px;
    padding-right: 60px;
  }

  .navbar {
    background: #000;
    top: 20px;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3Csvg width='36' height='36' viewBox='0 0 36 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='4' y='4' width='28' height='4' fill='white'/%3E%3Crect x='4' y='16' width='28' height='4' fill='white'/%3E%3Crect x='4' y='28' width='28' height='4' fill='white'/%3E%3C/svg%3E%0A");
  }

  .navbar .nav-item-btn {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 20px 0;
  }

  .mint-main {
    padding: 0;
    background: none;
  }

  .mint-boerder {
    padding-bottom: 0;
  }

  .minting-description {
    padding: 25px 30px;
  }

  .mint-box-item {
    padding: 24px 10px 48px;
  }

  .community-main {
    align-items: flex-end;
    gap: 32px;
  }

  .footer .copyright {
    padding-top: 1.5rem;
    line-height: 1.5;
  }

  .copyright,
  .footer .logo {
    text-align: center;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .heading-bg .heading {
    font-size: 32px;
  }

  .hero-heading {
    font-size: 48px;
  }

  .features-list-item {
    max-width: 318px;
  }

  .boundaries {
    padding-bottom: 40px;
  }

  .holding-chart .holding-heading {
    font-size: 14px;
  }

  .community-main {
    align-items: center;
    gap: 32px;
    padding: 0 10px 20px;
  }

  .community-img {
    margin-top: 30%;
  }
}

/*======================================
  Responsive Style End
======================================*/